import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Layout from "../../components/Layout";
import Card from "../../components/Card";
import { useEffect } from "react";

export default function Main() {
  const [foods, setFoods] = React.useState([{}]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/comidas`)
      .then((res) => res.json())
      .then((data) => setFoods(data));
  }, []);

  return (
    <Layout>
      <Container sx={{ py: 8 }} maxWidth="md">
        <Grid container spacing={4}>
          {foods.map((food) => (
            <Card food={food} key={`food-${food.id}`} />
          ))}
        </Grid>
      </Container>
    </Layout>
  );
}
