import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MaterialCard from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Recipe from "./Recipe";
import Nutritional from "./Nutritional";

export default function BigCard({ food }) {
  const { nombre, descripcion, variety, img } = food;
  const [openRecipe, setOpenRecipe] = React.useState(false);
  const [openNutritional, setOpenNutritional] = React.useState(false);

  const handleClickRecipe = () => {
    setOpenRecipe(true);
  };

  const handleClickNutritional = () => {
    setOpenNutritional(true);
  };

  return (
    <>
      <Grid item>
        <MaterialCard
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            maxWidth: 400,
            margin: "auto",
          }}
        >
          <CardMedia component="img" image={"./images/" + img} alt={nombre} />
          <CardContent sx={{ flexGrow: 1 }}>
            <Typography variant="h6" gutterBottom>
              <Link>{nombre}</Link>
            </Typography>
            <Typography variant="overline" display="block" gutterBottom>
              {variety}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {descripcion}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={handleClickRecipe}>
              Receta
            </Button>
            <Button size="small" onClick={handleClickNutritional}>
              Nutricional
            </Button>
          </CardActions>
        </MaterialCard>
      </Grid>
      <Recipe open={openRecipe} setOpen={setOpenRecipe} data={food} />
      <Nutritional
        open={openNutritional}
        setOpen={setOpenNutritional}
        data={food}
      />
    </>
  );
}
