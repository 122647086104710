import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "./utils/error-page.jsx";
import Home from "./pages/Home";
import Book from "./pages/Book";
import Food from "./pages/Food";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/rand",
    element: <Food />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/book",
    element: <Book />,
    errorElement: <ErrorPage />,
  },
]);

export default router;
