import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Layout from "../../components/Layout";
import BigCard from "../../components/BigCard";
import { useEffect } from "react";

export default function Main() {
  const [foods, setFoods] = React.useState([{}]);

  const randIndex = Math.floor(Math.random() * foods.length);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/comidas`)
      .then((res) => res.json())
      .then((data) => setFoods(data))
      .catch((error) => console.error("** Error **: ", error));
  }, []);

  return (
    <Layout>
      <Container sx={{ py: 8 }} maxWidth="md">
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <BigCard food={foods[randIndex]} />
        </Grid>
      </Container>
    </Layout>
  );
}
