import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";

export default function Main() {
  const navigate = useNavigate();

  const btnRandom = () => {
    navigate("/rand");
  };

  const btnBook = () => {
    navigate("/book");
  };

  return (
    <Layout>
      <Box
        sx={{
          bgcolor: "background.paper",
          height: "100vh",
          display: "flex",
          flexDirection: 'column',
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            ¿Qué comemos hoy?
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            A menudo lo mas complicado de cocinar es elegir que comer!!!
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button
              variant="contained"
              startIcon={<AutoFixHighIcon />}
              size="large"
              onClick={btnRandom}
            >
              Proponeme Algo Rico!
            </Button>
            <Button
              variant="outlined"
              endIcon={<MenuBookIcon />}
              size="large"
              onClick={btnBook}
            >
              Voy a la Carta
            </Button>
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
}
