import * as React from "react";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import Typography from "@mui/material/Typography";

const linkStyle = {
  color: "white",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
};

const iconStyle = {
  marginRight: "8px",
  fontSize: "2.0rem",
};

export default function Navbar() {
  return (
    <AppBar position="relative">
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap>
          <NavLink to="/" style={linkStyle}>
            <DinnerDiningIcon sx={iconStyle} />
            Morfeo
          </NavLink>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
