import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Web } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";

export default function Footer() {
  const theme = useTheme();
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.primary.main,
        p: 1,
        position: "fixed",
        bottom: 0,
        width: "100%",
        "@media (max-width:600px)": {
          position: "static", // Cambia a posición estática en dispositivos móviles
        },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Acerca de...
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Morfeo surgió de una pregunta que todos los días nos hacemos en
              casa... que comemos hoy?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Sugerencias
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Dejanos tu opinión o tu receta!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Seguinos
            </Typography>
            <Link
              sx={{ pr: 1 }}
              href="https://www.idealsoft.com.ar/"
              color="inherit"
            >
              <Web />
            </Link>
            <Link
              sx={{ pr: 1 }}
              href="https://www.facebook.com/idealsoftar"
              color="inherit"
            >
              <Facebook />
            </Link>
            <Link
              sx={{ pr: 1 }}
              href="https://www.instagram.com/idealsoftar"
              color="inherit"
            >
              <Instagram />
            </Link>
          </Grid>
        </Grid>
        <Box mt={1}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Hecho con ♥ por "}
            <Link color="inherit" href="https://idealsoft.com.ar">
              idealsoft.com.ar
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
