import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MaterialCard from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function Card ({ food }) {
  return (
    <>
    <Grid item xs={12} sm={6} md={4}>
        <MaterialCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardMedia component="img" image={'./images/'+food.img} alt={food.nombre} />
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" gutterBottom>
                    <Link>{food.nombre}</Link> 
                </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                    {food.variety}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    {food.descripcion}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small">Receta</Button>
                <Button size="small">Nutricional</Button>
            </CardActions>
        </MaterialCard>
    </Grid>
    </>
    )
};


